<template>

  <v-app style="background-color: #f2f4f3;">

    <v-container fluid class="pt-0 mt-0" fill-height align-center>
 
      <v-row justify="center">

      <v-col
        style="max-width: 600px;"
        color="primary">

        <v-card class="elevation-12">

          <v-card
            class="elevation-0 rounded-0 rounded-t pa-7 header-v-card d-flex justify-center mb-10"
            :color="isCardNet || isApiDocs ? '' : 'primary'">

            <div>
              <img v-if="isCardNet || isApiDocs" src="@/assets/images/cardnet-logo.jpg" style="max-width:100%; max-height:100%;" />
              <span v-else class="text-h4 white--text">
                Access Cash Spot
              </span>

              <!--<v-icon x-large class="pa-2" color="white">mdi-credit-card-search-outline</v-icon> -->
              <!--<v-icon x-large class="pa-2" color="white">mdi-credit-card-sync-outline</v-icon> -->
              <!--<v-icon x-large class="pa-2" color="white">mdi-credit-card-check-outline</v-icon> -->
            </div>

              <!--<v-img-->
              <!--src="@/assets/images/modded-logo-centered-full-text.png"-->
              <!--/>-->

              <!--<v-card-actions class="justify-center ma-0 pa-0 mt-4">-->
                <!--<v-chip color="white" class="font-weight-bold" outlined>{{ envName }}</v-chip>-->
              <!--</v-card-actions>-->

          </v-card>

          <v-form @submit.prevent="passwordSignIn">

          <v-card-text class="px-sm-10">
            <v-text-field
              id="username"
              label="Username"
              name="username"
              outlined
              prepend-inner-icon="mdi-account"
              type="text"
              v-model="username"
              placeholder=" "
              autocorrect="off"
              autocapitalize="none"
              autocomplete="username"
            />

            <v-text-field
              id="password"
              label="Password"
              name="password"
              outlined
              prepend-inner-icon="mdi-lock"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              v-model="password"
              @click:append="showPassword = !showPassword"
              placeholder=" "
              autocorrect="off"
              autocapitalize="none"
              autocomplete="current-password"
            />

            <v-alert v-if="passwordSignInError" type="error">
              {{ passwordSignInError }}
            </v-alert>
          </v-card-text>

          <v-card-actions class="pt-0 mt-0">
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              color="primary"
              dark
              >
              <v-icon>mdi-arrow-right-bold</v-icon>
            </v-btn>
          </v-card-actions>

          <v-card-actions class="pa-4 font-weight-thin body-2">
            <!--<router-link :to="{name: 'register'}">-->
              <!--I am an employee who has never been here before,-->
              <!--and I need to register.-->
            <!--</router-link>-->
            <!--<RegisterDialog />-->
          </v-card-actions>
          </v-form>

        </v-card>
      </v-col>
    </v-row>

  </v-container>
  </v-app>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
//import RegisterDialog from '@/components/RegisterDialog.vue'
import Console from '@/console'

//import GoogleLogin from 'vue-google-login'

// Button to login with google ui rendered using the renderParams object
    // The rendered button can't be use to logout since it is rendered by the google api and will only login
    // If you add the logoutButton param to true it will show a normal button without styles

export default {

  data() {
    return {
      username: '',
      password: '',
      showPassword: false,

      googleLoginParams: {
        client_id: '607507754912-e0lbbtb6q7f0ka7c3nkks8dpvob34ndr.apps.googleusercontent.com',
      },

      googleLoginRenderParams: {
        height: 50,
        longtitle: true
      },

      googleSignInError: undefined,
      passwordSignInError: undefined,
    }
  },

  metaInfo() {
    return {
      title: 'Sign In',
      meta: [
      ],
      script: [
      ]
    }
  },

  components: {
    //RegisterDialog,
  },

  created() {
    //this.$gAuth.signin2.render('my-signin2', {
    //  'scope': 'profile email',
    //  'width': 240,
    //  'height': 50,
    //  'longtitle': true,
    //  'theme': 'dark',
    //});
  },

  mounted() {
    document.addEventListener('AppleIDSignInOnSuccess', this.appleIdSignInSuccess)
    document.addEventListener('AppleIDSignInOnFailure', this.appleIdSignInFailure)
  },

  beforeDestroy() {
    document.removeEventListener('AppleIDSignInOnSuccess', this.appleIdSignInSuccess)
    document.removeEventListener('AppleIDSignInOnFailure', this.appleIdSignInFailure)
  },

  computed: {
    ...mapGetters('User', ['hasRoleName', 'rolesByName', 'clerkTerminalId']),
  },

  methods: {
    ...mapActions('Auth', ['signIn', 'signOut']),
    ...mapActions('Channel', ['getTerminalLimits']),

    googleLoginSuccess() {
      Console.log('googleLoginSuccess')
    },

    googleLoginFailure() {
      Console.error('googleLoginFailure')
    },

    async googleSignIn() {
      try {
        const { token } = await this.googleOAuth2SignIn(this.$gAuth)

        await this.signIn({oauth2_provider: 'google', oauth2_token: token});

        this.$router.push('/')
      }
      catch (err) {
        this.googleSignInError = err
        Console.error(err)
      }
    },

    async passwordSignIn() {
      const { username, password } = this

      try {
        await this.signIn({ username, password })

        if (this.isCashSpot && this.hasRoleName('clerk')) {
          const terminalId = this.clerkTerminalId

          try { await this.getTerminalLimits(terminalId) }
          catch (error) {
            if (error.response?.status == 404) {
              throw 'No Terminal Access Permissions'
            }
            else {
              throw error
            }
          }

          // Cashspot role verified
        }

        else if (this.isCardNet && this.hasRoleName('cardnet')) {
          // Cardnet role verified
        }

        else if (this.isApiDocs && this.hasRoleName('api-spec')) {
          // api-spec role verified
        }

        else {
          this.signOut()
          throw 'Access Denied'
        }

        this.$router.push('/')
      }

      catch (err) {
        this.passwordSignInError = err
        Console.error(err)
      }
    },

    appleIdSignInSuccess( { detail } ) {
      Console.log('appleIdSignInSuccess', detail)
    },

    appleIdSignInFailure(error) {
      Console.error('appleIdSignInFailure', error)
    }
  }
}
</script>

<style scoped>
.container {
}
.signin-buttons {
  width: 300px;
}
>>> #appleid-signin {
}
>>> #appleid-signin > div > div > svg {
  height: 55px;
  width: 100%;
}
>>> #google-signin {
  width: 100%;
}
</style>
